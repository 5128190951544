const baseStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.about_us': '/o-nas',
  'routes.become_partner': '/zostan-partnerem-code-europe',
  'routes.become_speaker': '/zostan-prelegentem',

  'routes.speaker': '/prelegenci/:slug',
  'routes.speakers': '/prelegenci',
  'routes.lecture_details_form': '/szczegoly-prelekcji',
  'routes.speaker_details_form': '/dane-prelegenta',

  'routes.buy_ticket': '/kup-bilet',
  'routes.buy_ticket_success': '/rejestracja-sukces',
  'routes.buy_ticket_group_success': '/kup-bilet-sukces',

  'routes.agenda_archive': '/agenda-archiwum/:year',
  'routes.agenda': '/agenda',

  'routes.companies': '/firmy',
  'routes.company': '/firmy/:slug',

  'routes.offers': '/oferty-pracy',
  'routes.offer': '/oferty-pracy/:slug',

  'routes.cookies_policy': '/polityka-cookies',
  'routes.privacy_policy': '/polityka-prywatnosci',

  'routes.code_of_conduct': '/zasady-postepowania',
  'routes.personal_data_processing': '/personal-data-processing',
  'routes.recording_agreement': '/recording-agreement',
  'routes.list_of_entities_employer': '/wykaz-podmiotow-pracodawca',
  'routes.list_of_entities_employer_outside_eog':
    '/wykaz-podmiotow-pracodawca-poza-eog',
  'routes.list_of_entities_user': '/wykaz-podmiotow-uzytkownik',
  'routes.list_of_entities_user_outside_eog':
    '/wykaz-podmiotow-uzytkownik-poza-eog',

  'routes.clients_regulations_pl': '/regulamin-dla-klientow',
  'routes.clients_regulations_en': '/general-terms-of-service',

  'routes.user': '/profil',
  'routes.user_regulations': '/regulamin-code-europe',

  'routes.contest': '/konkurs',
  'routes.kubota_contest': '/konkurs-kubota',
  'routes.covid_statement': '/oswiadczenie-covid',

  'routes.unsubscribe': '/unsubscribe',
  'routes.sign-in': '/logowanie',
  'routes.logout': '/wyloguj',
  'routes.forgot_password': '/zapomnialem-hasla',
  'routes.reset_password': '/zmien-haslo',

  'routes.not-found': '/404',

  topBarCoOrganizer: 'Partner strategiczny',
  heroHeading: 'Code Europe 2024',
  heroHomePageHeading:
    'Dołącz do <span>największego</span>{br}festiwalu IT w Polsce',
  heroAdditionalSubHeading: 'Connecting the world of Software Development',
  heroHomePageCta: 'Dołącz do największego w Polsce tech festivalu w 2025:',
  speakersHeroHeading:
    '<span>Zostań</span><span>prelegentem</span> Code Europe 2025',
  heroPartnersHeading:
    '<span>Code Europe</span>{br}Największy Tech Festival w Polsce',
  heroPartnersButtonText: 'Chcesz kupić bilety?',
  speakersHeroSubHeading:
    'Podziel się swoją wiedzą na temat języków programowania lub opowiedz{br} o innowacyjnych rozwiązaniach technologicznych.',
  speakersOfAllTimeHeading:
    'TOP prelegenci Code Europe{br}<span>ze wszystkich edycji</span>',
  speakersOfAllTimeSubHeading:
    'Zależy nam na zapewnieniu uczestnikom najwyższej jakości wystąpień oferowanych przez światowej klasy ekspertów. Mieliśmy przyjemność gościć w przeszłości wielu znakomitych prelegentów, m.in.:',
  callForPapersHeading:
    '<span>Call for Papers</span>{br}Dołącz do Code Europe jako Prelegent',
  callForPapersSubheader:
    'Dołącz do największego w Polsce festiwalu IT jako Prelegent i zainspiruj uczestników Code Europe!',
  heroAboutUsHeading:
    'Connecting the world of <span>Software Development</span>',
  heroAboutUsSubheader:
    'Code Europe to <span>największy w Polsce Tech Festival</span>, który przez tydzień gromadzi w kilku polskich miastach ekspertów z różnych środowisk i krajów.',
  heroAboutUsDescription:
    'Motto Code Europe to “Connecting the World of Software Development”, które realizujemy tworząc platformę wymiany doświadczeń i wiedzy prelegentów oraz ekspertów tech z różnych części świata i środowisk. Naszym celem jest aby Code Europe stał się wiodącym wydarzeniem technologicznym obejmującym pełny zakres rozwoju oprogramowania.',
  contactUsHeading:
    'Skontaktuj się z nami. <span>Chętnie z Tobą porozmawiamy!</span>',
  contactUsParticipants: 'Uczestnicy - pytania ogólne',
  contactUsFaq: 'a może nasze FAQ Ci pomoże?',
  contactUsCommunities: 'Społeczności',
  contactUsPartnerships: 'Partnerstwa i inne',
  contactUsPartners: 'Partnerzy',
  contactUsJoinPartner: 'Chcesz dołączyć do Code Europe jako Partner?',
  contactUsMedia: 'Media - zapytania ogólne',
  contactUsSpeakers: 'Prelegenci',
  contactUsJoinSpeaker: 'Chcesz dołączyć do Code Europe jako prelegent?',
  placeToBeForHeadingDesktop: 'Code Europe <span>to miejsce dla:</span>',
  placeToBeForHeadingMobile: 'Code Europe <span>to miejsce dla:</span>',
  teamCEHeading: 'Team Code Europe',
  teamCESubheader:
    'Zespół stojący za Code Europe to ludzie, którzy inspirują się technologią i widzą ogromną wartość w możliwości wymiana wiedzy i doświadczeń w jej rozwoju. Dlatego właśnie w 2016 roku stworzyliśmy wydarzenie Code Europe.',
  teamCEDescription:
    'To, co wyróżnia Code Europe, to focus na tworzeniu pozytywnego, inkluzywnego doświadczenia dla uczestników. Od jakości prelekcji i warsztatów po ogólną atmosferę wydarzenia, naszym celem jest zainspirowanie uczestników światem technologii i możliwościami, które daje. {br}{br}Nasza pasja i zaangażowanie odzwierciedlają pozytywne opinie uczestników. Wiemy, że nasze wysiłki mogą mieć wpływ na ich życie i rozwój karierowy, co motywuje nas do tego, aby każda edycja Code Europe była jeszcze lepsza niż poprzednia.',
  heroTicketsSalesStartSoon: 'Sprzedaż biletów wkrótce się rozpocznie!',
  followUsHeading: 'Śledź nas i bądź na bieżąco',
  followUsAboutUsHeading: 'Śledź nasze social media',
  tracksAtCE: 'Ścieżki na Code Europe',
  tracksHeading: 'Łączymy świat Software Developmentu',
  topicPathsHeading: 'Code <span>Europe</span> Tech Festival',
  tracksPartnersHeading: 'Ścieżki tematyczne',
  tracksDescription:
    "Festival Code Europe 2022 był z ogromnym sukcesem, ale nie powiedzieliśmy jeszcze ostatniego słowa. Sprawdźcie, jakie ścieżki pojawią się podczas Code Europe '23!",
  tracksPartnersDescription:
    'Mottem Code Europe jest "Connecting the World of Software Development". Dlatego rozdzieliliśmy prawie 100 planowanych prelekcji i warsztatów na 6 łączących się ścieżek tematycznych. To solidna dawka wiedzy dla uczestników o różnych zainteresowaniach na każdym poziomie!',
  topicPathsDescription:
    'Dwukrotnie więcej uczestników i ogromny sukces tegorocznej edycji to nie nasze ostatnie słowo. Więcej szczegółów nt. nadchodzącej edycji Code Europe już niedługo!',
  partnersHeading: '<span>Partnerzy</span> Code Europe',
  partnersButton: 'Przyciągaj najlepsze TECH talenty',
  partnersButtonMobile: 'Przyciągaj najlepsze TECH talenty',
  partnersCoorganizer: '<span>Współorganizator:</span>',
  partnersCity: '<span>City</span> partner Warsaw:',
  partnersSocial: 'Partner <span>społecznościowy:</span>',
  numbersHeading: 'Code Europe <span>w liczbach</span>',
  topPartnersHeading: 'Topowi <span>Partnerzy</span>',
  topPartnersSubheader:
    'Przyciągnij uwagę, buduj sieć kontaktów i rekrutuj najlepsze tech talenty na czołowym Tech & IT Festiwalu w Polsce. Bądź blisko swojej grupy docelowej - topowe firmy już tu są!',
  topPartnersRating: 'Średnia ocen',
  topPartnersRatingSurveys: 'na podstawie opinii 36 Partnerów',
  whyAttendHeading: 'Dlaczego warto wziąć udział?',
  whyAttendDescription:
    'Code Europe to największy w Polsce festiwal IT, który przez tydzień gromadzi w Krakowie specjalistów z różnych środowisk technologicznych.{br}Celem Code Europe jest połączenie świata Software Developmentu, zgromadzenie prelegentów i ekspertów IT z całego świata, o różnym doświadczeniu i wiedzy.{br}{br}Wszystko to w formie festiwalu, gdzie ważnym czynnikiem jest dobra zabawa.',
  whyAttendKnowledge: 'Wiedza na najwyższym poziomie',
  whyAttendKnowledgeDescription:
    'Code Europe gromadzi najlepszych prelegentów z całego świata - ucz się od najlepszych!',
  whyAttendConnect: 'Networking i dzielenie się wiedzą',
  whyAttendConnectDescription:
    'Poznaj podobnie myślących ludzi, wymień się pomysłami i bądź na bieżąco z najlepszymi praktykami i przyszłymi trendami w świecie IT.',
  whyAttendGrowth: 'Rozwój{br}{br}',
  whyAttendGrowthDescription:
    'Porozmawiaj się z TOP tech firmami i poznaj możliwości w branży.',
  whyAttendPartnersHeading:
    'Dlaczego warto dołączyć do <span>Code Europe?</span>',
  whyAttendPartnersDescription:
    'Osiągnij swoje cele rekrutacyjne i employer brandingowe jako Partner Code Europe.',
  whyAttendAttract:
    'Przyciągnij i rekrutuj{br}najlepszych specjalistów{br}{br}',
  whyAttendAttractDescription:
    'Gwarantowany bezpośredni dostęp do tysięcy specjalistów IT.',
  whyAttendEB: 'Wzmocnij swoją markę pracodawcy wśród idealnej grupy docelowej',
  whyAttendEBDescription:
    'Zaprezentujesz swoją firmę i jej projekty największej społeczności Tech w Polsce.',
  whyAttendSpectrum: 'Pełne spektrum branży{br}tech{br}{br}',
  whyAttendSpectrumDescription:
    'Code Europe łączy świat tworzenia oprogramowania. Skupiamy się na specjalistach z każdej dziedziny.',
  whyAttendCandidates: 'Wartościowa baza{br}kandydatów',
  whyAttendCandidatesDescription:
    'Uzyskasz dostęp do profili uczestników, których poznasz na Code Europe.',
  whyAttendEvent: 'Jedno z czołowych{br}wydarzeń IT w Europie',
  whyAttendEventDescription:
    'Bądź częścią wydarzenia cieszącego się niesłabnącą popularnością w społeczności IT. Pokaż swoją markę wśród topowych firm w branży tech.',
  whyAttendMarketing: 'Wielokanałowa kampania{br}marketingowa',
  whyAttendMarketingDescription:
    'Dołączając do Code Europe stajesz się częścią wielokanałowej kampanii marketingowej, która promuje Twoją markę wśród specjalistów IT.',
  joinCeNow: 'Dołącz do Code Europe',
  ceInNumbersHeading: 'Code Europe <span>w liczbach</span>',
  numbersDescription:
    'Code Europe to konferencja poświęcona najważniejszym trendom na rynku IT. Zapraszamy osoby, które chcą się rozwijać z wykorzystaniem najnowszych technologii w programowaniu pod okiem międzynarodowych specjalistów oraz wybitnych praktyków.',
  numbersUsersHeading: '10 000 uczestników',
  ceInNumbersParticipants: '<span>+18 439</span> Uczestników',
  ceInNumbersPartners: '<span>+372</span> Partnerów',
  numbersUsersDescription:
    'talentów z branży IT z Europy Środkowo - Wschodniej',
  numbersSpeakersHeading: '150 prelegentów z 16 krajów',
  ceInNumbersSpeakers: '<span>+401</span> TOP prelegentów',
  numbersSpeakersDescription:
    'gwiazdy świata IT oraz prezesi, managerowie topowych spółek technologicznych',
  numbersExhibitorsHeading: 'ponad 150 top wystawców',
  numbersLecturesHeading: '245 prelekcji i warsztatów',
  ceInNumbersWorkshops: '<span>+467</span> Warsztatów',
  numbersLecturesDescription:
    'od Big Data do Software Engineering zajmujemy się wszystkim co jest istotne dla dzisiejszej branży IT',
  speakersHeading:
    'Poznaj naszych prelegentów z{br}<span>Code Europe 2025</span>',
  showAllSpeakers: 'Więcej prelegentów',
  speakersCompaniesHeading: 'z <span>TOP</span> firm technologicznych',
  speakersCompaniesInformation: '* dane z poprzednich edycji Code Europe',
  speakersCompaniesInformationShort: '* dane z Code Europe 2018',
  workshopsHeading: '<span>Warsztaty i prelekcje</span>',
  workshopsDescription:
    'Rozwijaj swoje praktyczne umiejętności w nowych obszarach lub doskonal wiedzę, którą już posiadasz. Ucz się od światowej klasy specjalistów IT.',
  workshopsStaticInformation:
    'Czego się spodziewać po udziale w konferencji? Zobacz inspirujące prelekcje czołowych firm technologicznych z poprzednich edycji.',
  joinPartnersDataInformation: 'dane z poprzednich edycji Code Europe',
  joinPartnersButton: 'Zostań Partnerem',
  joinSpeakersButton: 'Zostań Prelegentem',
  partnersBannerHeading:
    'Dołącz do liderów branży Tech & IT na{br}<span>Code Europe 2025</span>',
  partnersBannerSubHeading:
    'Skontaktuj się z nami już dziś i zostań Partnerem Code Europe 2025 Tech Festival!',
  partnersBannerSubHeadingMobile:
    "Achieve your recruitment and employer branding goals with Code Europe, Poland's biggest IT festival.",
  latestEditionsHeading: '<span>Zobacz ostatnie edycje</span>{br}Code Europe',
  aftermovieHeading:
    'Sprawdź aftermovie z ostatniej <span>edycji Code Europe!</span>',
  LecturesAndRecordings: 'Nagrania prelekcji',
  PhotoGalleries: 'Galerie zdjęć',
  VideoSummary: 'Aftermovie',
  volunteersBannerHeading:
    '👋 Chcesz dołączyć jako{br}<span>Wolontariusz Code Europe?</span>',
  volunteersBannerHeadingMobile:
    '👋 Chcesz dołączyć jako{br}<span>Wolontariusz Code Europe</span>',
  graphsHeading:
    '<span>Najlepsi specjaliści i</span><span>managerowie IT</span> w jednym miejscu!',
  graphExpHeading: 'Doświadczenie zawodowe uczestników',
  graphExpOver5Years: 'więcej niż 5 lat',
  graphExp2To5Years: '3 do 5 lat',
  graphExpTo2Years: '2 do 3 lat',
  graphExpTo1Year: 'do roku',
  graphSpecHeading: 'Specjalizacja uczestników',
  graphSpecDevs: 'Developer / Programista',
  graphSpecAdmins: 'DevOps i SysOps',
  graphSpecAnalysts: 'Analityk',
  graphSpecEngineers: 'Inżynier',
  graphSpecManagers: 'Manager',
  graphSpecTesters: 'Tester',
  graphSpecOther: 'Inny',
  becomeSpeaker: 'Zostań prelegentem',
  partnerTweetsDescription:
    'Code Europe to nowatorska formuła, która w jednym miejscu koncentruje profesjonalistów z obszaru nowych technologii: potencjalnych członków Twojego zespołu, partnerów biznesowych oraz Twoich przyszłych klientów.',
  partnerJoinHeading:
    'Dołącz do <span>liderów</span><span>branży</span><span>IT</span> na Code Europe 2024',
  speakersJoinHeading: 'Czy to miejsce <span>dla mnie?</span>',
  partnerJoinDescription:
    'Skontaktuj się z nami, zostań Partnerem Code Europe 2024 już dzisiaj i razem twórzmy największy Tech Festival w Polsce!',
  speakersJoinDescription:
    'Jeżeli tegoroczna tematyka Code Europe jest Ci bliska - zgłoś się, wypełniając formularz u dołu strony.',
  speakersJoinPhotoDescriptionTitleSpecialists: 'Specjaliści',
  speakersJoinPhotoDescriptionSpecialists:
    'Code Europe wyróżniają{br}doskonali prelegenci, wybierani{br}spośród światowej klasy{br}specjalistów oraz twórców{br}i użytkowników pionierskich{br}rozwiązań technologicznych.',
  speakersJoinPhotoDescriptionTitleContent: 'Merytoryka',
  speakersJoinPhotoDescriptionContent:
    'To dzięki nim program{br}konferencji utrzymany jest{br}na najwyższym poziomie{br}merytorycznym, a udział{br}w wydarzeniu jest obowiązkowy{br}dla każdego pasjonata branży.',
  followUsAndFindOutFirst: '<span>Obserwuj nas</span> i bądź na bieżąco',
  newsletterHeading:
    '<span>Dołącz do newslettera,</span> aby dostawać informacje wcześniej!',
  newsletterPlaceholder: 'Twój adres e-mail',
  newsletterSubscribe: 'Zapisz się',
  newsletterError: 'Podałeś niepoprawny adres e-mail.',
  newsletterSuccess: 'Sprawdź swoją skrzynkę, aby aktywować newsletter.',
  newsletterPolicy:
    'Podając adres e-mail akceptujesz <a>Politykę prywatności</a>.',
  faqNavigation: 'FAQ',
  faqHeading: 'Frequently Asked Questions',
  faqLocationQuestion: 'Gdzie i kiedy odbywa się Code Europe 2025?',
  faqLocationAnswer:
    'Code Europe 2025 odbędzie się w Krakowie, czyli jednym z największych Polskich regionów pod względem rozwoju technologii.{br}{br} <span>Kraków</span>: 30.06-01.07.2025 w Centrum Kongresowym ICE Kraków',
  faqTimeQuestion: 'Kiedy rozpoczyna się i kończy wydarzenie Code Europe?',
  faqTimeAnswer:
    'Rejestracja Code Europe rozpocznie się od godziny 8:30 podczas pierwszego dnia. Oficjalne rozpoczęcie Code Europe odbędzie się o godzinie 9:30. Ostatnia sesja zacznie się odpowiednio około 16:00 pierwszego dnia i 16:00 drugiego dnia.{br}{br}Ponadto, po zakończeniu pierwszego dnia Code Europe odbędzie się impreza Afterparty, dla wszystkich uczestników, partnerów oraz prelegentów.',
  faqLineUpQuestion: 'Gdzie mogę sprawdzić line-up prelegentów?',
  faqLineUpAnswer:
    'Jeśli chcesz sprawdzić line-up występujących prelegentów to koniecznie odwiedź zakładkę <a>https://www.codeeurope.pl/en/speakers#Prelegenci</a>. Lista jest na bieżąco aktualizowana o kolejne potwierdzone gwiazdy. Oznacza to, że lista ciągle się powiększa, aż do ponad 100 prelegentów podczas Code Europe 2025!',
  faqAgendaQuestion: 'Gdzie mogę zapoznać się z agendą?',
  faqAgendaAnswer:
    'Agenda 2025 dostępna jest <a>https://www.codeeurope.pl/en/agenda#tutaj</a>.{br}{br}W miarę ogłaszania kolejnych prelegentów, jest ona na bieżąco aktualizowana przed każdą edycją naszego Tech Festivalu. Pełna wersja agendy publikowana jest na tydzień przed wydarzeniem.',
  faqRecordingsQuestion: 'Czy prelekcje są nagrywane i dostępne online?',
  faqRecordingsAnswer:
    'To zależy od chęci i zgody Prelegenta, ale większość wykładów Code Europe jest nagrywana, a następnie dostępna online na kanale Code Europe na <a>https://www.youtube.com/@CodeEurope#Youtube</a>.',
  faqHotelsQuestion: 'Czy polecacie jakiś hotel?',
  faqHotelsAnswer:
    'Oczywiście! Zarówno w Krakowie, jak i w Warszawie możesz skorzystać ze specjalnej oferty w hotelach o dogodnej lokalizacji.{br}{br} W <span>Krakowie</span> polecamy Ci Q Hotel Plus, który znajduje się naprzeciw miejsca wydarzenia. Kliknij <a>https://bit.ly/41EP7ai#tutaj</a>, aby skorzystać ze specjalnej promocji na pobyt.{br}{br} Jeśli wybierasz się na Code Europe w <span>Warszawie</span>, wówczas rekomendujemy hotel Hilton Warsaw City, będący również lokalizacją naszego Tech Festivalu. Bliżej się nie da! Aby zarezerwować promocyjny pobyt, kliknij <a>https://bit.ly/3Apb6pK#tutaj</a>.',
  faqTicketsQuestion: 'Gdzie mogę kupić bilety?',
  faqTicketsAnswer:
    'Bilety można nabyć online na <a>https://www.codeeurope.pl/en/buy-ticket#stronie Code Europe</a> lub z bezpośrednią pomocą naszego Zespołu Handlowców.',
  faqRegistrationQuestion: 'Czy muszę się zarejestrować, aby kupić bilet?',
  faqRegistrationAnswer:
    'Tak, aby kupić bilet niezbędne jest ukończenie procesu rejestracji.',
  faqTeamQuestion: 'Czy mogę kupić bilety dla mojego zespołu?',
  faqTeamAnswer:
    'Istnieje możliwość zakupu biletu grupowego, który obejmuje co najmniej 2 osoby. Następnie otrzymasz unikalny kod, który może być wykorzystany wielokrotnie przez różne osoby - w zależności od liczby zakupionych biletów. Na przykład, otrzymasz kod na 10 użyć i 10 osób może zarejestrować się przy użyciu tego kodu.',
  faqTicketPassQuestion: 'Czy mogę przekazać bilet komuś innemu?',
  faqTicketPassAnswer:
    'W sytuacji zakupu biletu pojedynczego nie ma możliwości przekazania biletu, ponieważ został zarejestrowany już na konkretną osobę. ' +
    'Jeśli chodzi o bilet grupowy - istnieje możliwość zakupu biletu grupowego, który obejmuje co najmniej 2 osoby. Następnie otrzymasz unikalny kod, który może być wykorzystany wielokrotnie przez różne osoby - w zależności od liczby zakupionych biletów. Na przykład, otrzymasz kod na 10 użyć i 10 osób może zarejestrować się przy użyciu tego kodu.',
  faqSingleTicketQuestion: 'Czy mogę kupić pojedynczy bilet dla kogoś innego?',
  faqSingleTicketAnswer:
    'Nie, nie ma możliwości zakupu pojedynczego biletu dla innej osoby. Jeśli potrzebujesz kupić pojedynczy bilet dla kogoś innego z Twojego zespołu, skontaktuj się z nami poprzez email <a>mailto:kontakt@codeeurope.pl#kontakt@codeeurope.pl</a> i pomożemy Ci indywidualnie.',
  faqInvoiceQuestion: 'W jaki sposób otrzymam bilet oraz fakturę?',
  faqInvoiceAnswer:
    'Po procesie rejestracji otrzymasz bilet oraz fakturę na mail, który został do tego wskazany w procesie rejestracji. Podczas zakupu biletu grupowego zostaniesz także zapytany o to na jaki adres email powinna zostać wysłana faktura.',
  faqStudentsQuestion: 'Czy są dostępne zniżki dla studentów?',
  faqStudentsAnswer:
    'Przez cały proces sprzedaży biletów obowiązuje jedna, niższa cena dla studentów. Zachęcamy wszystkie młode osoby do wzięcia udziału w Code Europe i czerpania inspiracji od międzynarodowych ekspertów!',
  faqTicketsDifferenceQuestion:
    'Jaka jest różnica między biletem studenckim a zwykłym?',
  faqTicketsDifferenceAnswer:
    'Bilet studencki jest dedykowany studentom, czyli młodym ludziom, którzy posiadają ważną legitymację studencką podczas procesu rejestracji oraz w okresie trwania Code Europe Tech Festival.',
  faqTicketsRefundQuestion: 'Zwrot pieniędzy za bilety - czy jest to możliwe?',
  faqTicketsRefundAnswer:
    'Nie jest to możliwe, zgodnie z polityką Code Europe i przepisami polskiego prawa.',
  faqFoodVoucherQuestion:
    'Jak mogę otrzymać i wykorzystać mój voucher na lunch?',
  faqFoodVoucherAnswer:
    'Podczas rejestracji otrzymasz dwa vouchery, po jednym na każdy dzień. Każdy voucher można wykorzystać tylko raz, w dniu określonym na voucherze.{br}{br}W Krakowie możesz wykorzystać swój voucher w strefie jedzenia. Będzie w niej spora różnorodność smaków do wyboru.',
  faqPicturesQuestion:
    'Czy mogę robić zdjęcia i nagrywać filmy podczas Code Europe?',
  faqPicturesAnswer:
    'Tak, można robić zdjęcia i filmy. Nie wolno jednak nagrywać sesji Prelegentów.',
  faqAfterpartyParticipateQuestion:
    'Afterparty - w jaki sposób mogę wziąć udział?',
  faqAfterpartyParticipateAnswer:
    'Każdy uczestnik Code Europe otrzymuje zaproszenie i bezpłatny wstęp na Code Europe Afterparty. Każdy otrzymuje odpowiednią instrukcję wejścia na imprezy i szczegóły miejsca, w którym się znajduje. Wejście na imprezę odbywa się na podstawie okazania biletu Code Europe.',
  faqAfterpartyVenueQuestion: 'Afterparty - gdzie znajdę miejsce wydarzenia?',
  faqAfterpartyVenueAnswer:
    'Oficjalne afterparty Code Europe w <span>Krakowie</span> odbędzie się w poniedziałek o 20:30, po zakończeniu pierwszego dnia wydarzenia!{br}{br}Podczas imprezy zapewniamy open bar oraz świetny DJ set!{br}{br}Lokalizacja miejsca imprezy oraz wszelkie instrukcje zostaną przesłane także bezpośrednio na adres e-mail, który został użyty podczas rejestracji.',
  skilledProfessionalsHeading:
    '<span>Doświadczeni specjaliści</span>{br}na Code Europe',
  skilledProfessionalsSubheader:
    'Nasza agenda powstaje z myślą o specjalistach z przynajmniej paroletnim i seniorskim doświadczeniem, którzy na Code Europe budują również swoją sieć kontaktów oraz szukają nowych projektów i możliwości rozwoju karierowego.',
  skilledProfessionalsExperience: 'Doświadczenie uczestników',
  moreThanFiveYears: 'powyżej 5 lat',
  threeToFiveYears: '3 do 5 lat',
  twoToThreeYears: '2 do 3 lat',
  upToOneYear: 'do roku',
  skilledProfessionalsSpecializations: 'Specjalizacje uczestników',
  developer: 'Deweloper / Programista',
  tester: 'Tester',
  manager: 'Manager',
  devOps: 'DevOps & SysOps',
  analyst: 'Analityk',
  engineer: 'Inżynier',
  architect: 'Architekt',
  other: 'Inne',
  activitiesHeading: 'Agenda wypełniona atrakcjami',
  activitiesSubHeading: 'Atrakcje na Code Europe',
  activitiesModalHeading: 'Activities',
  activitiesSchedule: 'Dwa dni, pełny harmonogram',
  activitiesDescription:
    'Podczas Code Europe Tech Festival czeka na Ciebie ogromna ilość wykładów, warsztatów, możliwości networkingu z rówieśnikami, ekspertami z branży i najlepszymi firmami. {br}{br}Aby sprostać byciu festiwalem technologii, Code Europe to także dobre jedzenie i mnóstwo zabawy przed, w trakcie i po.',
  activitiesLecturesTitle: 'Top Notch Lectures and Workshops',
  activitiesLecturesText:
    "Code Europe is focused on the full software development circle. This means that the festival is not just focused on one aspect of software development, but instead takes a more holistic approach that covers everything from coding to testing, deployment, and maintenance.{br}{br}By focusing on the full software development circle, Code Europe is able to provide attendees with a more comprehensive understanding of the software development process. This is incredibly valuable for IT professionals who want to improve their skills and stay up-to-date with the latest trends and best practices.{br}{br}In addition, by covering all aspects of software development, Code Europe is able to attract a diverse range of attendees, from developers and testers to project managers and business analysts. This creates a vibrant and collaborative atmosphere where people from different backgrounds and skill sets can come together to share ideas and learn from one another.{br}{br}And with the creator of C++, <a>Bjarne Stroustrup</a>, joining Code Europe as the first conference in Poland, it's clear that the festival is attracting some of the biggest names in the industry. This is incredibly exciting for attendees, as it provides them with a unique opportunity to learn from one of the most influential figures in the world of software development.",
  activitiesStreetFoodTitle: 'Street food Area and some fun!',
  activitiesStreetFoodText:
    "Coffee, street food or want to have some fun break? During Code Europe you'll be able to try different dishes from around the world, drink some fresh juices or just grab some coffee.{br}{br}During your break you be able to network and have some fun. In every city we prepared some fun activities.",
  activitiesNetworkingTitle:
    'Networking and career opportunities with industry leaders',
  activitiesNetworkingText:
    'At Code Europe we believe that sharing and exchanging perspectives is crucial when it comes to improving skills.{br}{br}Take the opportunity to connect with speakers, participants as well as check out career opportunities at various Code Europe partners, industry leaders. Gain valuable insights into the job market.',
  activitiesAfterpartyTitle: 'Great Code Europe Party!',
  activitiesAfterpartyText:
    "After first day full of lectures and workshops it's time for some fun and more networking opportunities!{br}{br}Speakers, Participants and our Partners are invited to join Code Europe's After Party which will take place in Cracow.{br}{br}Follow our Social Media to be up to date!",
  technologySkillsetHeading: 'Szerokie spektrum{br}<span>technologii</span>',
  technologySkillsetSubheader:
    'Agenda Code Europe obejmuje zarówno popularne jak i rozwijające się technologie, co przyciąga uczestników specjalizujących się w ich szerokiej gamie.',
  andMore: 'i więcej...',
  becomeSpeakerHeading:
    '<span>Wypełnij </span><span>formularz</span> i zostań prelegentem',
  becomeSpeakerAmenitiesHeading: 'Prelegentom Code Europe zapewniamy:',
  becomeSpeakerAmenityTransport: 'Transport na / z Code Europe',
  becomeSpeakerAmenityAccommodation: 'Nocleg',
  becomeSpeakerAmenitySupper: 'Kolacja w gronie prelegentów',
  becomeSpeakerAmenityAccess: 'Pełny dostęp do konferencji',
  becomeSpeakerCommentFirst:
    "I'm still living @code_europe in Poznań. Why this conference was so unique? Because participants stays to last speeches, that's why. Talking to the full-house is always a pleasure. ",
  becomeSpeakerCommentFirstAuthor: 'Michał Łukaszewski',
  becomeSpeakerCommentFirstInformation: '<strong>Intel AI</strong>',
  becomeSpeakerCommentSecond:
    'Did not expect to be in such an enormous room at @code_europe – such an honour to speak to so many people!',
  becomeSpeakerCommentSecondAuthor: 'Ryan Townsend',
  becomeSpeakerCommentSecondInformation: '<strong>ShiftCommerce CTO</strong>',
  becomeSpeakerFormHeading:
    'Bądź częścią największej konferencji programistycznej w Polsce i zgłoś chęć udziału do <span>30 marca 2025</span>',
  becomeSpeakerFormPolicy:
    'Wysyłając formularz akceptujesz <a>Politykę Prywatności</a>',
  becomeSpeakerSuccessHeading:
    'Dziękujemy!{br} Twoje zgłoszenie zostało wysłane.',
  becomeSpeakerSuccessSoon:
    'Wkrótce skontaktujemy się z Tobą w celu omówienia szczegółów.',
  becomeSpeakerSuccessExpect: 'Czego się spodziewać po udziale w konferencji?',
  becomeSpeakerSuccessSee:
    'Zobacz inspirujące prelekcje czołowych firm technologicznych z poprzednich edycji.',
  formFirstName: 'Imię',
  formFirstNameError: 'Popraw imię',
  formSurname: 'Nazwisko',
  formSurnameError: 'Popraw nazwisko',
  formEmail: 'Adres e-mail',
  formEmailError: 'Popraw email',
  formCompanyName: 'Nazwa firmy',
  formCompanyNameError: 'Podaj nazwę firmy',
  formLectureType: 'Rodzaj wystąpienia',
  formLectureTypeError: 'Wybierz rodzaj wystąpienia',
  formTechnologyTrack: 'Ścieżka technologiczna',
  formTechnologyTrackError: 'Wybierz ścieżkę technologiczną z listy',
  formTechnologies: 'Technologie',
  formTechnologiesError: 'Wybierz technologię z listy',
  formCities: 'Miasta wystąpienia',
  formCitiesError: 'Wybierz miasto',
  formTitle: 'Tytuł wystąpienia',
  formTitleError: 'Popraw tytuł',
  formDescription: 'Krótki opis wystąpienia',
  formDescriptionError: 'Popraw opis',
  formSend: 'Wyślij',
  lecturesHeading: '<span>Specyfika</span> wystąpień na Code Europe',
  lecturesSubheading:
    'Prelekcje są najistotniejszym elementem naszej konferencji, dlatego zależy nam, aby były tworzone w oparciu o 4 kluczowe kryteria:',
  lecturesContent: 'Wysoki poziom merytoryczny',
  lecturesSubjects: 'Tematyka zgodna z wiodącymi obszarami konferencji',
  lecturesForm: 'Sprecyzowana forma',
  lecturesTimeLimit: 'Określony czas wystąpienia',
  subscribe: 'Zapisz się',
  contactUs: 'Skontaktuj się z nami',
  dataFrom2018: 'dane z Code Europe 2022',
  tweetsHeading: 'Wasze <span>opinie</span> na temat Code Europe',
  tweetsHeadingSpeakers: 'Co <span>mówią o nas</span> prelegenci i prelegentki',
  previousEvents: 'Poprzednie konferencje',
  lectures: 'Prelekcje',
  organizers: 'Organizatorzy',
  contact: 'Kontakt',
  soon: 'Wkrótce!',
  followUs: 'Śledź nas',
  forPartners: 'Dla partnerów',
  forSpeakers: 'Dla prelegentów',
  watchVideo: 'Zobacz Video',
  seeMore: 'Zobacz więcej',
  showMore: 'Więcej',
  showLess: 'Mniej',
  offersPlural:
    '{count, plural, one {# oferta} =2 {# oferty} =3 {# oferty} =4 {# oferty} =22 {# oferty} =23 {# oferty} =24 {# oferty} =32 {# oferty} =33 {# oferty} =34 {# oferty} other {# ofert}}',
  send: 'Wyślij',
  loading: 'Szukam...',
  '3city': '3cITy',
  cracow: 'Kraków',
  warsaw: 'Warszawa',
  citiesSpring2022: 'Kraków, Warszawa, Trójmiasto',
  seoTitle: 'Największy Tech Festival w Polsce - Code Europe',
  seoTitleAboutUs: 'Zespół Code Europe - Największy Tech Festival w Polsce',
  seoTitleSpeaker:
    '{name} na Code Europe - Największym Tech Festivalu w Polsce',
  seoTitleSpeakers:
    'Prelegenci na Code Europe - Największym Tech Festivalu w Polsce',
  seoTitlePartners:
    'Partnerzy na Code Europe - Największy Tech Festival w Polsce',
  seoTitleTickets:
    'Wybierz miasto i bilet na największy Tech Festival w Polsce - Code Europe',
  seoTitleBecomeSpeaker:
    'Dołącz jako Prelegent do Code Europe - Call of Papers',
  seoDescription:
    'Code Europe to największy w Polsce Tech Festival. Gromadzimy światowej klasy ekspertów i prelegentów z całego świata, którzy dzielą się swoją wiedzą z tysiącami specjalistów IT. Bądź częścią Code Europe i otrzymaj wiedzę na światowym poziomie!',
  seoDescriptionAboutUs:
    'Dowiedz się więcej o Code Europe i zespole, który za nim stoi. Celem Code Europe jest zgromadzenie w Polsce prelegentów i ekspertów z całego świata, o różnym doświadczeniu i wiedzy, aby łączyć świat rozwoju oprogramowania. Connecting the world of software development',
  seoDescriptionSpeakers:
    'Sprawdź w jakim mieście wystąpią poszczególni prelegenci Code Europe. Wybierz interesującą Cię technologię oraz miasto i zdecyduj jakie prelekcje musisz odwiedzić.',
  seoDescriptionSpeaker:
    '{name} na Code Europe. Sprawdź wystąpienia {name} na Code Europe i dołącz do Code Europe, największego Tech Festivalu w Polsce.',
  seoTitleCompanies:
    'Partnerzy Code Europe - Największego Tech Festivalu w Polsce',
  seoDescriptionCompanies:
    'Sprawdź jakie firmy będą na największym festiwalu technologii w Polsce - Code Europe.',
  seoDescriptionPartners:
    'Code Europe to największy Tech Festival w Polsce. Tutaj znajdziesz specjalistów i menedżerów szeroko pojętego IT, nawiążesz kontakty z pożądanym talentem i zrekrutujesz wymarzonych specjalistów IT.',
  seoDescriptionTickets:
    'Code Europe to największy w Polsce Tech Festival. Gromadzimy światowej klasy ekspertów i prelegentów z całego świata, którzy dzielą się swoją wiedzą z tysiącami specjalistów IT. Bądź częścią Code Europe i otrzymaj wiedzę na światowym poziomie!',
  seoDescriptionBecomeSpeaker:
    'Zgłoś się i dołącz jako prelegent na Code Europe. Call for papers jest otwarty.',
  seoKeywords:
    'Code Europe, Konferencja IT, Konferencja programistyczna, backend, mobile, big data',
  seoKeywordsAboutUs:
    'Organizatorzy Code Europe, o Organizatorach Code Europe, Zespół Code Europe, Code Europe, Idea Code Europe',
  seoKeywordsSpeakers:
    'Prelegenci Code Europe, wykładowcy code europe, wykłady code europe, prezentacje code europe, javascript, backend, big data, java, C++, php, sql, python',
  seoKeywordsSpeaker:
    'Prelegenci Code Europe, wykładowcy code europe, wykłady code europe, prezentacje code europe, {name}',
  seoKeywordsCompanies:
    'Firmy na code europe, firmy code europe, jakie firmy będą na code europe',
  seoKeywordsPartners:
    'Code Europe, Targi pracy IT, targi pracy programistyczne, rekrutacja specjalistów IT, partnerzy w IT, rekrutacja IT, konferencja IT',
  seoKeywordsTickets:
    'Code Europe, Konferencja IT, Konferencja programistyczna, backend, mobile, big data',
  titleUser:
    'Twój Profil na Code Europe  Największy Tech Festival w Polsce - Code Europe',
  newsletterUnsubSuccessHeading: 'Wypisałeś się z Newslettera.',
  newsletterUnsubSuccessDescription:
    'Nię będziesz otrzymywał najnowszych informacji dotyczących Code Europe 2024. Jeżeli chcesz być na bieżąco zapisz się ponownie.',
  newsletterUnsubErrorHeading: 'Oooops… Coś poszło nie tak!',
  newsletterUnsubErrorDescription:
    "Możesz napisać do nas: <a href='mailto:kontakt@codeeurope.pl'>kontakt@codeeurope.pl</a>",
  newsletterUnsubButton: 'Wróć na stronę główną',
  getTicketButton: 'Kup bilet',
  getTicketNowButton: 'Kup bilet',
  searchButton: 'Szukaj',
  noResults: 'Brak wyników',
  privacyPolicy: 'Polityka prywatności',
  codeOfConduct: 'Zasady postępowania',
  regulations: 'Regulamin',
  regulationsUsers: 'Regulamin dla uczestników',
  selectLanguagePlaceholder: 'Wybierz język',
  spDetailsType: 'Określ typ wystąpienia',
  spDetailsTypeLecture: 'Prelekcja',
  spDetailsTypeWorkshop: 'Warsztat',
  spDetailsTypeError: 'Wybierz typ wystąpienia',
  spDetailsCities: 'Wybierz miasto',
  spDetailsCitiesError: 'Wybierz przynajmniej jedną lokalizację',
  spDetailsName: 'Nazwa prelegenta',
  spDetailsNameError: 'Nazwa prelegenta jest wymagana',
  spDetailsNameMinError: 'Przynajmniej jeden prelegent',
  spDetailsEmailMinError: 'Przynajmniej jeden adress e-mail prelegenta',
  spDetailsEmailRequiredError: 'Pole e-mail jest wymagane',
  spDetailsEmailValidMail: 'Wpisz poprawny adres e-mail',
  SHeaderSpeaker: 'Drogi Prelegencie,',
  SHeaderDescription:
    'Proszę wypełnić poniższy formularz. Informacje te zostaną opublikowane na stronie internetowej Code Europe oraz w materiałach promocyjnych.',
  spDetailsEmail: 'Adres e-mail prelegenta',
  spDetailsTitle: 'Tytuł Prelekcji / Warsztatu',
  spDetailsTitleError: 'Tytuł jest wymagany',
  spDetailsTitleMaxLengthError: 'Tytuł jest zbyt długi',
  spDetailsDescription: 'Krótki opis',
  addAnotherSpeaker: 'Dodaj prelegenta',
  removeSpeaker: 'Usuń',
  spDetailsDescriptionError: 'Opis jest wymagany',
  participantsCountMinError: 'The number must be greater than 0',
  participantsCountMaxError: 'The number must be less than 200',
  spDetailsLanguage: 'Język',
  spDetailsLanguageError: 'To pole jest wymagane',
  spDetailsExperience: 'Wymagany poziom doświadczenia',
  spDetailsExperienceInfo:
    'Określ poziom doświadczenia wymagany od uczestników (1 - początkujący, 3 - zaawansowany)',
  spDetailsExperienceLevel: 'Poziom',
  spDetailsTrack: 'Wybierz ścieżkę swojego wykładu/warsztatu',
  spDetailsTrackError: 'To pole jest wymagane',
  spDetailsTech: 'Pola technologiczne',
  spDetailsTechError: 'Wybierz przynajmniej jedną technologię',
  spDetailsRequirements: 'Wymagania dla uczestników',
  spDetailsParticipants: 'Liczba uczestników',
  SLabelDisclaimer:
    'Jeśli brakuje jakiejś technologii, możesz dodać swoją, wpisując to, co chcesz.',
  spDetailsIfWorkshopSelected: 'Wypełnij, jeśli wybrano "warsztat"',
  getToKnowUs: 'Poznaj nas bliżej',
  findOutMore: 'Dowiedz się więcej',
  ourTeamHeading: '<span>Nasz zespół</span>',
  ourTeamDescription:
    'Team Code Europe to oddani wspólnej idei pasjonaci, którzy pełni zaangażowania działają dniami i nocami, by stworzyć najlepszą konferencję programistyczną w Polsce. Szczególnie motywują nas opinie zadowolonych oraz podekscytowanych wizją kolejnych edycji wydarzenia uczestników!',
  ourTeamBanner: 'Rekrutuj najlepszych specjalistów i menedżerów IT!',
  aboutUsHeading: '<span>Code Europe to:</span>',
  aboutUsSubheading:
    'wyjątkowy punkt na mapie wydarzeń IT w Polsce. Konferencja służy jako platforma do wymiany doświadczeń pomiędzy pasjonatami i światowej klasy ekspertami branży nowych technologii. Szczególnymi wyróżnikami Code Europe są:',
  aboutUsSpeakers: 'Wyjątkowi prelegenci',
  aboutUsTalks: 'Pasjonujące wykłady',
  aboutUsScale: 'Niezwykła skala i atmosfera',
  proffesionsAboutUsHeading:
    '<span>Code Europe</span> skupia się na pełnym cyklu rozwoju oprogramowania',
  proffesionsAboutUsSubheader:
    'Największy w Polsce Tech Festival skierowany jest do szerokiego grona odbiorców o różnych kompetencjach.',
  partnersAboutUsHeading: 'Partnerzy <span>Code Europe</span>',
  partnersAboutUsSubheader:
    'Fakt, że Code Europe cieszy się uznaniem wiodących marek, jest potwierdzeniem sukcesu wydarzenia i wartości, jaką tworzy dla branży Tech. Wraz z rozwojem i ewolucją formuły festiwalu, dołącza do niego coraz więcej topowych organizacji, które chcą wesprzeć to ekscytujące i inspirujące wydarzenie.',
  speakerDescriptionError: 'The description cannot exceed 850 characters',
  speakerDescriptionMinError:
    'The description must be at least 10 characters long',
  organiser: 'Organizator:',
  contactUsModalTitle: 'Jesteś zainteresowany zostaniem Partnerem festiwalu?',
  contactUsModalDescription: 'Skontaktuj się z nami!',
  'filtrationGroupName.cities': 'Wybierz miasto',
  'filtrationGroupName.tags': 'Wybierz technologię',
  'filtrationGroupName.tracks': 'Wybierz ścieżkę',
  'filtrationGroupName.employeesCount': 'Liczba pracowników',
  'filtrationGroupName.jobAreas': 'Obszar stanowiska',
  'filtrationGroupName.salaries': 'Wynagrodzenia (PLN)',
  'filtrationGroupName.languages': 'Język wystąpienia',
  filtrationShowMore: 'Więcej',
  filtrationShowLess: 'Mniej',
  filtrationLabel: 'Filtry',
  filtration: 'Filtracja',
  filtrationClear: 'Wyczyść',
  filtrationClearDesktop: 'Wyczyść filtrowanie',
  filtrationSubmit: 'Filtruj',
  speakers: 'Prelegenci',
  agenda2023: 'Agenda 2023',
  agenda: 'Agenda {year}',
  workshops: 'Warsztaty',
  jobOffers: 'Oferty pracy',
  companies: 'Firmy',
  buyTicket: 'Kup bilet',
  signIn: 'Zaloguj się',
  myAccount: 'Moje konto',
  organization: 'Organizacja',
  location: 'Lokalizacja i dojazd',
  aboutUs: 'O nas',
  edition: 'Edycja',
  newsletterHeadingNav: 'Zapisz się do newslettera',
  newsletterHeadingNavText:
    'Zdobądź dostęp do najnowszych informacji o Code Europe.',
  newsletterSend: 'Wyślij',
  'speakers.bannerHeading': 'Podziel się swoją wiedzą na Code Europe 2025',
  'speakers.empty':
    'Brak prelegentów, którzy odpowiadają obecnym kryteriom. Możesz zmienić miasto, ścieżkę tematyczną lub obszar technologiczny.',
  'speakers.emptyButton': 'Pokaż wszystkich',
  'companies.empty':
    'Brak firm, które odpowiadają obecnym kryteriom. Możesz zmienić miasto lub obszar technologiczny.',
  'companies.emptyButton': 'Pokaż wszystkie',
  'companies.search': 'Nazwa firmy...',
  'skill.speakers_think': 'Krytyczne myślenie',
  'skill.speakers_problems': 'Rozwiązywanie problemów',
  'skill.speakers_program': 'Programowanie',
  'skill.speakers_system': 'Analiza systemowa',
  'skill.speakers_error': 'Usuwanie błędów',
  'skill.speakers_lazi': 'Lenistwo',
  'skill.basic': 'Podstawowy',
  'skill.advanced': 'Zaawansowany',
  'skill.expert': 'Ekspert',
  'speaker.mySkills': 'Moje umiejętności',
  'speaker.bio': 'Bio',
  'speeches.emptyHeading': 'Moje wystąpienia',
  'speeches.emptyInformation': 'Więcej informacji już wkrótce. Śledź nas na ',
  pomeraniaBannerText:
    '<strong>Invest in pomerania</strong> wspiera rozwój <span>pomorskiego biznesu</span>',
  'employer.cities': 'Spotkasz nas na Code Europe:',
  'employer.tracks': 'Ścieżki tematyczne:',
  'employer.tags': 'Technologie w firmie:',
  'employer.description': 'Kariera w ',
  'employer.benefits': 'Benefity w firmie:',
  'employer.info': 'Informacje o firmie:',
  'employer.industry': 'Branża: ',
  'employer.employeesCount': 'Liczba pracowników: ',
  'employer.headquarters': 'Siedziba: ',
  'employer.socials': 'Web:',
  'employer.similar': 'Firmy z podobnymi technologiami',
  'employer.offers': 'Oferty pracy w firmie {company}',
  'wewnetrzne-i-zewnetrzne-szkolenia': 'internal-external-trainings',
  'hack-days': 'hack-days',
  'budzet-konferencyjny': 'conference-budget',
  'budzet-szkoleniowy': 'training-budget',
  'team-events': 'team-events',
  'prywatna-opieka-zdrowotna': 'private-health-care',
  'karta-sportowa': 'sport-card',
  kuchnia: 'kitchen',
  stolowka: 'canteen',
  'darmowa-kawa': 'free-coffee',
  'darmowy-lunch': 'free-lunch',
  'darmowe-napoje': 'free-beverages',
  'darmowe-przekaski': 'free-snacks',
  owoce: 'fruits',

  'darmowy-parking': 'free-parking',
  'parkinng-rowerowy': 'bike-parking',
  prysznic: 'shower',
  przedszkole: 'kindergarten',
  silownia: 'gym',
  'chill-room': 'play-room-chill-room',
  'telefon-sluzbowy': 'mobile-phone',
  seoTitleCompany:
    '{company} na Code Europe - Największym Tech Festivalu w Polsce',
  seoTitleAgenda:
    'Agenda Code Europe - Connecting the world of software development',
  seoDescriptionAgenda:
    'Agenda największego Tech Festivalu w Polsce Code Europe. Wejdź, znajdź interesujące Cię tematy i zapisz się już teraz!',
  seoDescriptionCompany:
    'Spotkaj się z {company} na Code Europe - największym Tech Festivalu w Polsce. ',
  seoKeywordsCompany:
    'Firmy na code europe, firmy code europe, jakie firmy będą na code europe, {company} na code europe, {company} code europe',
  seoKeywordsAgenda:
    'Agenda Code Europe, Code Europe tematy, Code Europe prelekcje, Code Europe lista tematów',
  seoTitleAgendaArchive:
    'Agenda Code Europe {year} - Najważniejsze wydarzenie dla profesjonalistów IT w Polsce',
  seoDescriptionAgendaArchive:
    'Sprawdź agendę Code Europe {year} - największego festiwalu technologicznego w Polsce. Odkryj najgorętsze tematy w świecie IT. Zapisz się już teraz na tegoroczną edycję!',
  seoKeywordsAgendaArchive:
    'Agenda Code Europe {year}, Nowości IT, Trendy IT, Konferencja IT, Code Europe {year}, Prelekcje Code Europe {year}, Wydarzenia IT',
  'tickets.selectHeading':
    '<span>Code Europe - </span><span> bądź jego częścią!</span>',
  'tickets.selectSubheading':
    'To dwa dni wypełnione merytoryką, inspirującymi spotkaniami, a także świetną zabawą i niezapomnianym doświadczeniem!{br}Zakup bilety już teraz i nie przegap najkorzystniejszej oferty!',
  'tickets.bannerHeading':
    'Dzięki współorganizatorom możesz skorzystać z <strong>50% zniżki na zakup biletu</strong> w Trójmieście.',
  'tickets.bannerUseCode': 'Użyj kodu',
  'tickets.selectDescription':
    'Wybierz liczbę biletów, jaką chcesz kupić. Po zakończeniu transakcji otrzymasz kody biletowe, które rozdysponujesz pomiędzy członków swojego zespołu.',
  'tickets.selectStudentInformation':
    'Uczestniku, wybrałeś bilet studencki - pamiętaj, by na wydarzenie zabrać dokument upoważniający do ulgi (legitymacja studencka).',
  'tickets.selectDiscountCodeHeading':
    'Jeśli posiadasz kod rabatowy, możesz wykorzystać go tutaj',
  'tickets.selectDiscountCodeLabel': 'Wprowadź kod promocyjny',
  'tickets.selectDetailsHeading': 'Szczegóły zamówienia',
  'tickets.selectDetailsEmpty': 'Wybierz bilet',
  'tickets.selectTicketType': 'Wybierz rodzaj biletu',
  'tickets.selectPass': 'Wybierz swój bilet',
  'tickets.soldOut': 'SOLD OUT',
  'tickets.ticketDetailsType': 'Bilet na wydarzenie',
  'tickets.discountDisabledPlaceholder': 'Wybierz bilet',
  'tickets.ticketTypeStudent': 'Bilet studencki',
  'tickets.ticketTypeEvent': 'Bilet na wydarzenie',
  'tickets.ticketPriceIncludingVat': 'w tym VAT',
  'tickets.ticketChoose': 'Wybierz',
  'tickets.ticketAmount': 'Liczba biletów',
  'tickets.orderPrice': 'Wartość zamówienia',
  'tickets.userRegistrationHeading': 'Zarejestruj się',
  'tickets.haveAccount': 'Masz konto',
  'tickets.userBadgeHeading': 'Przygotuj swój badge uczestnika',
  'tickets.userBadgeDescription':
    "Uzupełnij informacje, które wykorzystamy do przygotowania badge'a uczestnika - Twojego unikatowego identyfikatora na czas konferencji.",
  'tickets.userDataHeading': 'Twoje doświadczenie',
  'tickets.userDataDescription':
    'Wskaż swoje doświadczenie zawodowe i zainteresowania. Te informacje pozwalają nam budować wydarzenie w jeszcze większym stopniu odpowiadającego Twoim potrzebom i oczekiwaniom.',
  'tickets.discountCode': 'Kod rabatowy',
  'tickets.priceSummaryTotal': 'Suma (w tym VAT)',
  'tickets.orderSummaryLabel': 'Podsumowanie zamówienia',
  'tickets.orderSummaryPaymentLabel': 'Dane do płatności',
  'tickets.paymentSummaryVatInfo':
    'Faktura VAT zostanie wysłana na Twój adres e-mail.',
  'tickets.successHeading': 'Zajrzyj na skrzynkę',
  'tickets.successInfo':
    'Po zaksięgowaniu wpłaty bilet i dokument sprzedaży wyślemy na adres: <span>{email}</span>',
  'tickets.successInfoGroup':
    'Kody na bilety zostaną wysłane po zaksięgowaniu wpłaty na Twój adres e-mail.',
  'tickets.successInfoSpam':
    'Jeśli nie widzisz naszej wiadomości, sprawdź folder SPAM.',
  'tickets.successInfoEdit':
    'W każdej chwili możesz edytować swoje dane i pobrać bilet logując się na <a>konto użytkownika</a>.',
  'tickets.chooseCity': 'Wybierz miasto',

  'tickets.stepperRegister': 'Rejestracja Uzytkownika',
  'tickets.stepperBadge': 'Badge Uczestnika',
  'tickets.stepperUserData': 'Informacje dodatkowe',
  'tickets.stepperPaymentData': 'Dane do płatności',
  'tickets.stepperOrderSummary': 'Podsumowanie i płatności',

  'tickets.optionEarlyBirdPass': 'Early Bird Pass',
  'tickets.optionDecidedPass': 'Decided Pass',
  'tickets.optionRegularPass': 'Regular Pass',

  'labels.name': 'Imię',
  'labels.surname': 'Nazwisko',
  'labels.phone': 'Numer telefonu',
  'labels.email': 'Adres e-mail',
  'labels.password': 'Hasło',
  'labels.passwordConfirm': 'Powtórz hasło',
  'labels.passwordRequirements':
    'Hasło powinno zawierać co najmniej 8 naków (1 wielką literę, 1 cyfrę i 1 znak specialny: @$!%>£=()^-_*#+?&).',
  'labels.passwordEqual': 'Hasła muszą być identyczne',
  'labels.terms': 'Akceptuję <a>Regulamin</a> Code Europe.',
  'labels.jobPosition': 'Moje stanowisko',
  'labels.company': 'Nazwa firmy',
  'labels.interestingPath': 'Najbardziej interesująca mnie ścieżka tematyczna',
  'labels.experience': 'Moje doświadczenie zawodowe',
  'labels.technologiesWorkingWith': 'Technologie, w których pracuję',
  'labels.technologiesInterestedIn': 'Technologie, które mnie interesują',
  'labels.knownLanguages': 'Języki obce',
  'labels.cv': 'Moje CV',
  'labels.cvRequirements':
    'Prześlij CV, by jeszcze lepiej dać się poznać potencjalnym pracodawcom IT. Maksymalny rozmiar pliku to 5 MB, akceptujemy rozszerzenie .pdf',
  'labels.street': 'Ulica',
  'labels.houseNumber': 'Nr domu',
  'labels.apartmentNumber': 'Nr lokalu',
  'labels.postcode': 'Kod pocztowy',
  'labels.city': 'Miasto',
  'labels.country': 'Kraj',
  'labels.vat': 'Chcę otrzymać fakturę VAT na firmę',
  'labels.companyNip': 'NIP',
  'labels.copy': 'Przenieś dane adresowe',
  'labels.matchMaking':
    'Chcę brać udział w procesach rekrutacyjnych na stanowisko zbieżne z moim doświadczeniem i zainteresowaniami.',

  'errors.nameError': 'Popraw imię',
  'errors.surnameError': 'Popraw nazwisko',
  'errors.phoneError': 'Popraw numer telefonu',
  'errors.emailError': 'Popraw adres e-mail',
  'errors.paymentEmailError': 'Popraw adres e-mail',
  'errors.passwordError':
    'Popraw hasło. Hasło powinno zawierać co najmniej 8 naków (1 wielką literę, 1 cyfrę i 1 znak specialny: @$!%>£=()^-_*#+?&).',
  'errors.jobPositionError': 'Popraw stanowisko',
  'errors.companyError': 'Popraw nazwę firmy',
  'errors.interestingPathError': 'Popraw ścieżkę tematyczną',
  'errors.technologiesWorkingWithOverError': 'Wybierz maks. 5 technologii',
  'errors.technologiesInterestedInOverError': 'Wybierz maks. 5 technologii',
  'errors.streetError': 'Popraw nazwę ulicy',
  'errors.companyStreetError': 'Popraw nazwę ulicy',
  'errors.houseNumberError': 'Popraw numer domu',
  'errors.companyHouseNumberError': 'Popraw numer domu',
  'errors.apartmentNumberError': 'Popraw numer mieszkania',
  'errors.companyApartmentNumberError': 'Popraw numer mieszkania',
  'errors.postcodeError': 'Popraw kod pocztowy',
  'errors.companyPostcodeError': 'Popraw kod pocztowy',
  'errors.cityError': 'Popraw miasto',
  'errors.companyCityError': 'Popraw miasto',
  'errors.countryError': 'Popraw kraj',
  'errors.companyCountryError': 'Popraw kraj',
  'errors.companyNipError': 'Popraw NIP',
  'errors.resumeError': 'Dodaj poprawny plik CV',

  'errors.discountCodeNotFound': 'Podano błędny kod',
  'errors.discountCodeCannotApply': 'Podano błędny kod',
  'errors.incorrectLoginData': 'Niepoprawne dane logowania',

  'signin.heading': 'Zaloguj się',
  'signin.subheading': 'Chcesz wziąć udział w wydarzeniu? <a>Kup bilet.</a>',
  'signin.forgotPassword': 'Nie pamiętasz hasła',

  'user.tabTickets': 'Bilety',
  'user.tabData': 'Dane',
  'user.tabConsent': 'Zgody',
  'user.dataRegistrationSection': 'Dane rejestracyjne',

  'user.ticketsNeedHelpLabel': 'Potrzebujesz pomocy',
  'user.ticketsNeedHelp': 'Skontaktuj się z nami',

  'user.ticketStatusSuccess': 'Pobierz bilet',
  'user.ticketStatusPending': 'Oczekujemy na potwierdzenie płatności',
  'user.ticketStatusCanceled': 'Płatność odrzucona',

  'user.consentRemoveAccountLabel': 'Usunięcie konta Użytkownika',
  'user.consentRemoveAccount':
    'Konto pozwala Ci edytować dane oraz pobrać bilet na wydarzenie w każdej chwili. Pamiętaj jednak, że zawsze możesz poprosić o usunięcie konta, pisząc na ',

  'user.forgotPasswordHeading': 'Resetuj hasło',
  'user.forgotPasswordDescription':
    'Wpisz adres e-mail użyty przy rejestracji, a następnie kliknij w link, który wyślemy na Twoją skrzynkę',
  'user.forgotPasswordSubmit': 'Resetuj hasło',
  'user.forgotSuccess':
    'Na podany adres e-mail został wysłany link z dalszą instrukcją',

  'user.resetPasswordHeading': 'Zmiana hasła',
  'user.resetPasswordDescription':
    'Wpisz swoje nowe hasło. Unikaj oczywistych haseł, jak imię czy nazwisko.',
  'user.resetPasswordSubmit': 'Zmień hasło',
  'user.resetSuccess': 'Hasło zostało poprawnie zaktualizowane',

  'general.back': 'Wróć',
  'general.next': 'Dalej',
  'general.or': 'lub',
  'general.summary': 'Podsumowanie',
  'general.ready': 'Gotowe',
  'general.logout': 'Wyloguj',
  'general.saveData': 'Zapisz dane',

  offers:
    '{count, plural, one {# oferta} =2 {# oferty} =3 {# oferty} =4 {# oferty} =22 {# oferty} =23 {# oferty} =24 {# oferty} =32 {# oferty} =33 {# oferty} =34 {# oferty} other {# ofert}}',

  'offers.empty':
    'Brak ofert pracy, które odpowiadają obecnym kryteriom. Możesz zmienić obszar stanowiska, wynagrodzenie lub wymagane umiejętności.',
  'offers.emptyButton': 'Pokaż wszystkie',
  'offers.search': 'Stanowisko, nazwa firmy, słowo kluczowe, lokalizacja…',
  'offer.endtime': 'Rekrutacja trwa do: ',
  'offer.responsibilitiesHeading': 'Czym będziesz się zajmować?',
  'offer.basicInfoHeading': 'Pozostałe informacje',
  'offer.projectHeading': 'Opis projektu',
  'offer.descriptionHeading': 'Podstawowe informacje',
  'offer.profileHeading': 'Profil',
  'offer.skillsHeading': 'Wymagane umiejętności:',
  'offer.languagesHeading': 'Wymagane języki obce',
  'offer.employeesCount': 'Wielkość firmy: ',
  'offer.teamCount': 'Liczba członków zespołu: ',
  'offer.salary': 'Wynagrodzenie: ',
  'offer.contract': 'Forma współpracy: ',
  'offer.industry': 'Branża: ',
  'offer.benefitsHeading': 'Benefity w firmie: ',
  'offer.interested': 'Zaciekawiła Cię ta oferta?',
  'profile.new_features': 'Nowe funkcjonalności',
  'profile.maintenance_bug_fixing': 'Konserwacja/Naprawa',
  'profile.client_support': 'Praca z klientem',
  'profile.document_writing': 'Pisanie dokumentacji',
  'profile.meetings': 'Spotkania',
  requirements: 'Wymagania: ',
  apply: 'Aplikuj',
  seoTitleOffers: 'Oferty Pracy IT - Code Europe',
  seoDescriptionOffers:
    'Oferty Pracy dla programistów i specjalistów IT. Tutaj znajdziesz najciekawsze ofert pracy w branży IT. Zrób karierę w świecie IT z nami!',
  seoKeywordsOffers:
    'Oferty pracy code europe, oferty pracy w it, praca w it, it praca, oferty pracy it, praca code europe',
  seoTitleOffer: '{name} - Oferta Pracy Code Europe',
  seoDescriptionOffer:
    '{name} Oferta Pracy. Znajdziesz najciekawsze oferty pracy dla programistów i specjalistów Tech w Europie. Z nami zrobisz karierę w IT!',
  seoKeywordsOffer:
    'Code Europe job offers, IT job offers, job offers in IT, IT work, working in IT, Code Europe work, {name} job offer, {name} work',

  'event.type_lecture': 'Prelekcja',
  'event.type_workshop': 'Work IT',

  'agenda.empty':
    'Brak wydarzeń, które odpowiadają obecnym kryteriom. Możesz zmienić miasto, obszar technologiczny lub ścieżkę tematyczną.',
  'agenda.emptyButton': 'Pokaż wszystkie',
  'agenda.seeFull': 'Zobacz pełną agendę',
  'agenda.technologies': 'Technologie',
  'agenda.level': 'Poziom',

  '404.homePage': 'Strona główna',
  '404.notFound': 'Podana strona nie istnieje',
  promoBannerText: 'ZGARRRNIJ BILET',

  topicDevOpsDescription:
    'Wszystko o chmurach, sieciach, danych. Zarządzanie infrastrukturą, najlepsze praktyki, narzędzia i knowhow.',
  topicCybersecurityDescription:
    'Technologie i narzędzia bezpieczeństwa, szyfrowania, osłony przed atakami, technologie i zachowania wzmacniające bezpieczeństwo.',
  topicDataScienceDescription:
    'Deep & Machine Learning, Big Data & Hurtownie danych, wizualizacja oraz analiza, połączenia neuronowe i sztuczna inteligencja.',
  topicProgrammingDescription:
    'Frontend, Backend, biblioteki, narzędzia i najlepsze praktyki, najnowsze trendy, architektura oprogramowania, C++, .NET, Python, JAVA, C#, Javascript, Golang, PWA.',
  topicFutureDescription:
    'Blockchain, robotyka, Metaverse, kosmonautyka, pojazdy autonomiczne.',
  topicDevelopmentDescription:
    "It's all about execution. Najlepsze praktyki, narzędzia i techniki.",

  earlyBird: 'Decided',
  earlyBirdTickets: 'Pula biletów',
  earlyBirdAvailable:
    'dostępne tylko do <span>22 Maja,</span> <span>2022!</span>',
  earlyBirdInfo: 'Po tej dacie cena STANDARD wyniesie 689 zł',

  encourageTicketBannerHeading: 'Zdecydowan_?',
  encourageTicketBannerDescription: 'Kup bilet teraz! Cena wzrośnie za: ',

  eventCountdownBannerHeading:
    '<span>Pośpiesz się!</span> Code Europe rozpoczyna się za {time}',
  countdownPassPriceInfo: ' wzrośnie za ',
  countdownPassPricePlOnly: 'Cena biletu ',
  days: 'dni',
  day: 'dzień',
  firstDay: 'DZIEŃ 1',
  secondDay: 'DZIEŃ 2',
  hurryUp: 'Pośpiesz się!',
  codeEuropeDateFirst: '30 czerwca - 1 lipca 2025',
  codeEuropeDateSecond: '13 - 14.06.2024',
  softwareDevelopers: 'Software{br}Developers',
  softwareArchitects: 'Software{br}Architects',
  devOpsEngineers: 'DevOps{br}Engineers',
  securityExperts: 'Security{br}Experts',
  productOwners: 'Product{br}Owners',
  dataScientists: 'Data{br}Scientists',
  qASpecialists: 'QA{br}Specialists',
  impressions: 'Oczami partnerów i prelegentów',
  learnMore: 'Dowiedz się więcej',
  explore: 'Explore',
  legal: 'Legal',
  applyNow: 'Zgłoś się',
  soundOn: 'włącz dźwięk',
  mute: 'wycisz',
  hello: 'Cześć!',
  videoPopupContactUs: 'Zostań partnerem',
}

export type LanguageStrings = typeof baseStrings
export const pl = baseStrings
