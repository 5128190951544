import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { animated, Spring } from 'react-spring'
import VisibilitySensor from 'react-visibility-sensor'

import { SlideTop, TopicPaths, Button } from '../../components'
import twoLinesPattern from '../../assets/patterns/two-lines-pattern.svg'
import LongGreenPattern from '../../assets/patterns/LongGreenPattern'
import { SContainer, SBackgroundContent } from './Tracks.styled'
import useLocalizeRoute from '../../utils/useLocalizeRoute'
import { AppRoute } from 'const'
import { useBreakpoint } from '../../modules/breakpoint'
import preventClick from 'utils/preventClick'
function Tracks({ partners }) {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const breakpoints = useBreakpoint()

  return (
    <VisibilitySensor
      minTopValue={15}
      offset={{ top: 300 }}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => {
        return (
          <SContainer {...{ partners }}>
            {!breakpoints.lg ? (
              <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
                {({ opacity }) => {
                  return (
                    <div className="background">
                      <animated.div
                        style={{
                          opacity,
                          height: '100%',
                        }}
                      >
                        <SBackgroundContent>
                          <div className="positionLeft">
                            <img src={twoLinesPattern} alt="" />
                          </div>
                        </SBackgroundContent>
                        {!partners ? (
                          <div className="positionRight">
                            <LongGreenPattern />
                          </div>
                        ) : null}
                      </animated.div>
                    </div>
                  )
                }}
              </Spring>
            ) : null}
            <div className="content">
              <SlideTop>
                <p className="top-title">
                  {' '}
                  {formatMessage({ id: 'tracksAtCE' })}
                </p>
                <h2 className="heading">
                  {formatMessage({
                    id: partners ? 'tracksPartnersHeading' : 'tracksHeading',
                  })}
                </h2>
              </SlideTop>
              <SlideTop>
                <p className="subheader">
                  {formatMessage({
                    id: partners
                      ? 'tracksPartnersDescription'
                      : 'tracksDescription',
                  })}
                </p>
              </SlideTop>
              <TopicPaths />
              {!partners ? (
                <div className="button-wrapper">
                  <Button
                    as={Link}
                    // to="#"
                    // className="disable-button-link"
                    // onClick={preventClick}
                    to={localizeRouteKey(AppRoute.BuyTicket)}
                  >
                    {formatMessage({
                      id: 'buyTicket',
                    })}
                    <i className="ri-arrow-right-line" />
                  </Button>
                </div>
              ) : null}
            </div>
          </SContainer>
        )
      }}
    </VisibilitySensor>
  )
}

export default Tracks
